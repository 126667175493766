import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Footer from "../components/footer"
import TeamMember from "../components/teamMember"

const imageQuery = graphql`
  query {
    andreu: file(relativePath: { eq: "andreu_covas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carmen: file(relativePath: { eq: "carmen_gonzalez2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const teamMembers = [
  {
    key: "andreu",
    name: "Andreu Covas",
    description: [
      "Soy un profesional con más de 20 años de experiencia en el ámbito de la consultoría sanitaria. Entre mis especialidades se encuentra consultoría participativa, habiendo dirigido más de un millar de advisory board, sesiones con expertos y grupos de trabajo con profesionales sanitarios de referencia nacional en multitud de especialidades (oncología, hematología, reumatología, cardiología, urología, neumología, ginecología, ...).",
      "También cuento con amplia experiencia en el desarrollo de proyectos de consultoría estratégica (diseño y desarrollo de planes estratégicos en el sector salud).",
    ]
  },
  {
    key: "carmen",
    name: "Carmen González",
    description: [
      "Soy farmacéutica y trabajo en el sector de la consultoría sanitaria desde hace más de 10 años. He liderado cientos de reuniones de trabajo y sesiones participativas con profesionales de diferentes especialidades clínicas, desde la cardiología a la oncología, pasando por enfermedades neurológicas o reumatológicas; he dirigido y desarrollado proyectos de Formación Médica Continuada, tanto presenciales como a distancia, incluyendo la gestión de la acreditación de los mismos.",
      "También, a lo largo de estos años, he colaborado activamente en el desarrollo de plataformas tecnológicas y aplicaciones web, realizando los análisis funcionales y prototipos de las mismas. El ámbito de las nuevas tecnologías me apasiona, y es por eso por lo que me gusta estar al día, lo que me permite aportar a los proyectos un punto de innovación.",
    ],
    thumbnailRight: true,
  },
]

const TeamPage = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <Layout title="Quiénes somos">
      <div className="slider-wrapper">
        <Slider title="Quiénes somos" />
      </div>

      <div className="fix">
        <div className="team-area">
          <div className="team-wrapper ptb--60">
            <div className="container">
              {teamMembers.map(member => (
                <TeamMember {...member} thumbnail={data[member.key]} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default TeamPage
