import React from "react"
import Img from "gatsby-image"
import { useMediaQuery } from 'react-responsive'

const Thumbnail = ({ thumbnail }) => (
  <div className="col-lg-5">
    <div className="thumbnail">
      <Img fluid={thumbnail.childImageSharp.fluid} />
    </div>
  </div>
)

const Paragraph = ({ content }) => <p className="description">{content}</p>

const TeamMember = ({
  key,
  name,
  description,
  thumbnail,
  linkedin,
  thumbnailRight = false,
}) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 991px)' })

  return (
    <div className="row align-items-center mb--80">
      {(!thumbnailRight || isSmallScreen) && <Thumbnail thumbnail={thumbnail} />}
      <div className="col-lg-7">
        <div className="about-inner inner">
          <div className="section-title">
            <h3 className="title mb--50">{name}</h3>
            {description.map((paragraph, idx) => (
              <Paragraph key={`${key}-desc-${idx}`} content={paragraph} />
            ))}
          </div>
        </div>
      </div>
      {thumbnailRight && !isSmallScreen && <Thumbnail thumbnail={thumbnail} />}
    </div>
  )
}

export default TeamMember
